import React, { useRef, useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
// import Layout from "../components/layout"

import undefinedLogoWhite from "../images/undefinedLogoWhite.png";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import blob1 from "../images/blobs/blob1-2.svg";
import blob3img from "../images/blobs/blob3img.svg";
import sd1 from "../images/splashdash1.png";
import "./feature.css";
import Contact from "../components/contact";

const colorArr = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
];

export default function BlogPost({ data }) {
  const swiper = useRef(null);
  const [curColor, setCurColor] = useState(
    colorArr[Math.floor(Math.random() * colorArr.length)]
  );
  useEffect(() => {
    swiper.current = new Swiper(".swiper-container");
    //add necessary parameters required by checking the offical docs of swiper
    const cursor = document.getElementById("cursor");
    let mouseX = 0;
    let mouseY = 0;

    let cursorX = 0;
    let cursorY = 0;

    let speed = 0.3;

    function animate() {
      let distX = mouseX - cursorX;
      let distY = mouseY - cursorY;

      cursorX = cursorX + distX * speed;
      cursorY = cursorY + distY * speed;

      cursor.style.left = cursorX + "px";
      cursor.style.top = cursorY + "px";

      requestAnimationFrame(animate);
    }
    animate();

    document.addEventListener("mousemove", function (event) {
      // mouseX = event.pageX;
      // mouseY = event.pageY;
      mouseX = event.clientX;
      mouseY = event.clientY;
    });

    var hoverItems = document.getElementsByClassName("hoverable");
    Array.from(hoverItems).forEach(function (element) {
      element.addEventListener("mouseenter", function (event) {
        cursor.classList.add("active");
      });
      element.addEventListener("mouseleave", function (event) {
        cursor.classList.remove("active");
      });
    });
  }, []);
  const featureData = data.airtable.data;
  console.log("Data is: ", featureData);
  return (
    // <Layout>
    <React.Fragment>
      <div id="cursor"></div>

      <div className="feature-wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Undefined | " + featureData.Name}</title>
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={"Undefined | " + featureData.Name}
          />
          <meta property="og:description" content={featureData.Description} />
          <meta
            property="og:url"
            content={"https://undefinedllc.com" + featureData.Slug}
          />
          <meta property="og:site_name" content="Undefined LLC" />
          {/* <link
          rel="canonical"
          href={"https://undefinedllc.com/" + featureData.Slug}
        /> */}
        </Helmet>
        <div
          style={{
            // height: 300,
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{
              height: "100%",
              width: "100%",
              transform: "scaleX(-1) scaleY(-1)",
            }}
          >
            <path
              d="M-1.69,-1.47 C162.52,252.13 235.32,-23.19 500.56,132.72 L500.00,150.00 L0.00,150.00 Z"
              // style={{ stroke: "none", fill: "rgb(52, 152, 219)" }}
              style={{ stroke: "none", fill: "#212121" }}
            ></path>
          </svg>
        </div>
        <img
          className="hoverable"
          src={undefinedLogoWhite}
          alt="undefined_logo"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: 100,
            zIndex: 100,
          }}
          onClick={() => {
            navigate("/");
          }}
        ></img>
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <div className="section-portfolio-hero">
          {/* <div
            className="section-portfolio-hero-img"
            style={{
              backgroundImage:
                "url(" + featureData.Hero?.localFiles[0].publicURL + ")",
              bottom: -300,
            }}
          ></div> */}
          <BackgroundImage
            className="section-portfolio-hero-img"
            style={{
              bottom: -300,
            }}
            fluid={featureData.Hero?.localFiles[0].childImageSharp.fluid}
          ></BackgroundImage>
          {/* <div style={{ marginLeft: 50, marginBottom: 30, minWidth: 475 }}> */}
          <div className="section-container-inner">
            <h4
              className="section-portfolio-hero-subtext"
              style={{ color: "yellow" }}
            >
              {/* Maybe use this color? #00FF00 */}
              {/* <span style={{ color: "yellow" }}>0{ind + 1}</span>{" "} */}
              {featureData.ShortDescription}
            </h4>
            <div className="section-header">
              <h1
                className="section-header-text"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "start",
                }}
              >
                {featureData.Name}
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4 className="section-portfolio-hero-subtext">
                <span style={{ color: "yellow" }}>Platform</span>
                <br></br>
                {featureData.ProjectType}
              </h4>
              <h4 className="section-portfolio-hero-subtext">
                <span style={{ color: "yellow" }}>Year</span>
                <br></br>
                {featureData.CompletedDate}
              </h4>
            </div>
            {/* <div
              className="section-portfolio-view-button hoverable"
              onClick={() => {
                navigate("/" + featureData.Slug);
              }}
            >
              View
            </div> */}
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            transform: "scaleY(1.2)",
            // background: "#212121",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "yellow" }}></div>
        </div>
        <div
          id="section-solution"
          className="section-container"
          style={{
            backgroundColor: "yellow",
            display: "flex",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text" style={{ color: "black" }}></h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div className="description-content" style={{ width: 1100 }}>
              <div style={{ transform: "scale(-1,-1)", textAlign: "end" }}>
                <FormatQuoteIcon style={{ fontSize: 90 }} />
              </div>
              <div className="section-header">
                <h1
                  className="section-header-text"
                  style={{ color: "black", margin: 0 }}
                >
                  {featureData.Description}
                </h1>
              </div>
              <div style={{ textAlign: "end" }}>
                <FormatQuoteIcon style={{ fontSize: 90 }} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "40vh",
            width: "100vw",
            background: "yellow",
          }}
        >
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div>
        <div
          id="section-problem"
          className="section-container"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text">The Problem</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div className="problem-content">
              <h3
                className="section-contact-subheader-text"
                style={{ width: 300, textAlign: "end" }}
              >
                {featureData.Problem ||
                  `We're always looking to connect with potential clients and peers
                in the field. Feel free to either fill out the form or send us
                an email with any questions you have`}
              </h3>
              <div className="feature-section-image">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0"
                  y="0"
                  width="115"
                  height="115"
                  viewBox="0, 0, 115, 115"
                  style={{ height: "100%", width: "100%" }}
                >
                  <defs>
                    <clipPath id="user-space" clipPathUnits="userSpaceOnUse">
                      {/* <path
                      fill="#FF0066"
                      d="M49.5,-65.4C58.2,-51.8,55.1,-30.5,54.2,-12.9C53.4,4.7,54.7,18.5,49.1,28.6C43.6,38.6,31.1,44.8,17.6,50.9C4.1,57,-10.5,62.8,-22.5,59.5C-34.5,56.1,-44.1,43.5,-53.7,29.7C-63.3,15.8,-73,0.6,-73.4,-15.7C-73.8,-32,-65,-49.4,-51.2,-62.2C-37.4,-75,-18.7,-83.1,0.9,-84.1C20.4,-85.2,40.9,-79.1,49.5,-65.4Z"
                      transform="translate(100 100)"
                    ></path> */}
                      <path
                        d="M95.743,22.262 C106.843,39.162 107.243,64.462 96.343,82.762 C85.443,101.062 63.343,112.362 43.043,111.362 C22.743,110.262 4.243,96.962 0.643,82.862 C-2.857,68.762 8.643,54.062 17.943,38.162 C27.143,22.262 34.243,5.362 48.643,1.062 C62.943,-3.138 84.743,5.362 95.743,22.262 z"
                        fill="#FF0066"
                      />
                    </clipPath>
                  </defs>

                  <image
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                    //   xlinkHref="https://source.unsplash.com/random"
                    xlinkHref={featureData.Hero?.localFiles[0].publicURL}
                    clipPath="url(#user-space)"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "#212121",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "yellow" }}></div>
        </div>
        <div
          id="section-solution"
          className="section-container"
          style={{
            backgroundColor: "yellow",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text" style={{ color: "black" }}>
              The Solution
            </h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div className="solution-content">
              <h3
                className="section-contact-subheader-text"
                style={{ color: "black", width: 300, textAlign: "start" }}
              >
                {featureData.Solution ||
                  `We're always looking to connect with potential clients and peers
                  in the field. Feel free to either fill out the form or send us
                  an email with any questions you have
                  `}
              </h3>
              <div
                className="feature-section-image"
                style={{ transform: "scaleX(-1)" }}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0"
                  y="0"
                  width="115"
                  height="115"
                  viewBox="0, 0, 115, 115"
                  style={{ height: "100%", width: "100%" }}
                >
                  <defs>
                    <clipPath id="user-space" clipPathUnits="userSpaceOnUse">
                      {/* <path
                      fill="#FF0066"
                      d="M49.5,-65.4C58.2,-51.8,55.1,-30.5,54.2,-12.9C53.4,4.7,54.7,18.5,49.1,28.6C43.6,38.6,31.1,44.8,17.6,50.9C4.1,57,-10.5,62.8,-22.5,59.5C-34.5,56.1,-44.1,43.5,-53.7,29.7C-63.3,15.8,-73,0.6,-73.4,-15.7C-73.8,-32,-65,-49.4,-51.2,-62.2C-37.4,-75,-18.7,-83.1,0.9,-84.1C20.4,-85.2,40.9,-79.1,49.5,-65.4Z"
                      transform="translate(100 100)"
                    ></path> */}
                      <path
                        d="M95.743,22.262 C106.843,39.162 107.243,64.462 96.343,82.762 C85.443,101.062 63.343,112.362 43.043,111.362 C22.743,110.262 4.243,96.962 0.643,82.862 C-2.857,68.762 8.643,54.062 17.943,38.162 C27.143,22.262 34.243,5.362 48.643,1.062 C62.943,-3.138 84.743,5.362 95.743,22.262 z"
                        fill="#FF0066"
                      />
                    </clipPath>
                  </defs>

                  <image
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                    //   xlinkHref="https://source.unsplash.com/random"
                    xlinkHref={featureData.Hero?.localFiles[0].publicURL}
                    clipPath="url(#user-space)"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "yellow",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "#414141" }}></div>
        </div>
        <div
          id="section-solution"
          className="section-container"
          style={{
            backgroundColor: "#414141",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text">The Tech</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div className="problem-content">
              <h3
                className="section-contact-subheader-text"
                style={{ width: 300, textAlign: "end" }}
              >
                {featureData.Technologies ||
                  `We're always looking to connect with potential clients and peers
                  in the field. Feel free to either fill out the form or send us
                  an email with any questions you have
                  `}
              </h3>
              <div
                className="feature-section-image"
                style={{ transform: "scaleX(1)" }}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0"
                  y="0"
                  width="115"
                  height="115"
                  viewBox="0, 0, 115, 115"
                  style={{ height: "100%", width: "100%" }}
                >
                  <defs>
                    <clipPath id="user-space" clipPathUnits="userSpaceOnUse">
                      {/* <path
                      fill="#FF0066"
                      d="M49.5,-65.4C58.2,-51.8,55.1,-30.5,54.2,-12.9C53.4,4.7,54.7,18.5,49.1,28.6C43.6,38.6,31.1,44.8,17.6,50.9C4.1,57,-10.5,62.8,-22.5,59.5C-34.5,56.1,-44.1,43.5,-53.7,29.7C-63.3,15.8,-73,0.6,-73.4,-15.7C-73.8,-32,-65,-49.4,-51.2,-62.2C-37.4,-75,-18.7,-83.1,0.9,-84.1C20.4,-85.2,40.9,-79.1,49.5,-65.4Z"
                      transform="translate(100 100)"
                    ></path> */}
                      <path
                        d="M95.743,22.262 C106.843,39.162 107.243,64.462 96.343,82.762 C85.443,101.062 63.343,112.362 43.043,111.362 C22.743,110.262 4.243,96.962 0.643,82.862 C-2.857,68.762 8.643,54.062 17.943,38.162 C27.143,22.262 34.243,5.362 48.643,1.062 C62.943,-3.138 84.743,5.362 95.743,22.262 z"
                        fill="#FF0066"
                      />
                    </clipPath>
                  </defs>

                  <image
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                    //   xlinkHref="https://source.unsplash.com/random"
                    xlinkHref={featureData.Hero?.localFiles[0].publicURL}
                    clipPath="url(#user-space)"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "#414141",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div>
        <div
          id="section-contact"
          className="section-container"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text">Get in touch</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 40,
            }}
          >
            <div style={{ width: 700 }}>
              <h3 className="section-contact-subheader-text">
                We're always looking to connect with potential clients and peers
                in the field. Feel free to either fill out the form or send us
                an email with any questions you have
              </h3>
            </div>
          </div>
          <Contact />
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "#212121",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "#303030" }}></div>
        </div>
      </div>
    </React.Fragment>
    // </Layout>
  );
}
export const query = graphql`
  query MyQuery($slug: String) {
    airtable(data: { Slug: { eq: $slug } }) {
      data {
        Name
        Slug
        ShortDescription
        Description
        Technologies
        ProjectType
        CompletedDate
        Problem
        Solution
        Logo {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Hero {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
        ProblemPhoto {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        SolutionPhoto {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        TechPhoto {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
